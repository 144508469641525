export const ROLES = {
  ADMIN: {
    id: "ADMIN",
  },
  MEMBER: {
    id: "MEMBER",
  },
};

export const STATUSES = {
  INVITED: {
    id: "INVITED",
    label: "Invited",
  },
  ACTIVE: {
    id: "ACTIVE",
    label: "Active",
  },
  REMOVED: {
    id: "REMOVED",
    label: "Removed",
  },
  REVOKED: {
    id: "REVOKED",
    label: "Revoked",
  },
  DECLINED: {
    id: "DECLINED",
    label: "Declined",
  },
};
