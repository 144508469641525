import useMe from "../hooks/use_me";

import LoginView from "./LoginView";

/**
 * AuthContainer is used by _app for all pages. It’s easier to break out into a
 * component for testing, since it uses the use_me hook.
 */
export default function AuthContainer({ authRequired = true, children }) {
  const { me, error } = useMe();

  if (authRequired === false || (!error && me)) {
    return children;
  } else if (error) {
    return <LoginView />;
  }

  return null;
}
