import { createContext, useEffect, useState } from "react";

import useWorkspaceSWR from "@cosy/hooks/use_workspace_swr";

export const IndexJobsContext = createContext();

export function IndexJobsProvider(props) {
  const [indexJobs, setIndexJobs] = useState([]);
  const { data: jobs } = useWorkspaceSWR("/jobs", {
    refreshInterval: 5000,
  });

  useEffect(() => {
    setIndexJobs(jobs?.map((job) => job.searchSource) || []);
  }, [jobs]);

  return (
    <IndexJobsContext.Provider value={{ indexJobs, setIndexJobs }}>
      {props.children}
    </IndexJobsContext.Provider>
  );
}
