import { useCallback } from "react";
import { useCookie } from "react-use";

/**
 * Returns the value and methods for working with the last visited Workspace
 * cookie, and updates the cookie when it sees a new Workspace.
 *
 * @returns {Array}
 */
export default function useLastVisitedWorkspace() {
  const [
    lastVisitedWorkspace,
    setLastVisitedWorkspaceCookie,
    clearLastVisitedWorkspace,
  ] = useCookie("lastVisitedWorkspace");

  const setLastVisitedWorkspace = useCallback(
    (value) => {
      setLastVisitedWorkspaceCookie(value, {
        domain: process.env.NEXT_PUBLIC_COOKIE_DOMAIN,
      });
    },
    [setLastVisitedWorkspaceCookie]
  );

  return [
    lastVisitedWorkspace,
    setLastVisitedWorkspace,
    clearLastVisitedWorkspace,
  ];
}
