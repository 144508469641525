import useSWR from "swr";

import useSlug from "@cosy/hooks/use_slug";
/**
 * Returns an SWR call prefixed with `/api/workspaces/[slug]`.
 *
 * @param {...any} args
 * @returns {object} SWR values (data, validate, error).
 */
export default function useWorkspaceSWR(...args) {
  const slug = useSlug();
  const [path, ...rest] = args;
  return useSWR(() => slug && `/api/workspaces/${slug}${path}`, ...rest);
}
