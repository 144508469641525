/**
 * @file A set of helper functions for calling fetch on URLs which are
 * prepended with "/api".
 */
import merge from "lodash/merge";

import fetcher from "./fetcher";

/**
 * @typedef {object} ApiResponse
 * @property {object} data - Data returned from the fetch call.
 * @property {Error} error - Error returned from the fetch call.
 */

/**
 * Performs a DELETE call.
 *
 * @param {string} url - A path to be prefixed with '/api' and fetched.
 * @returns {ApiResponse}
 */
export function del(url) {
  return _fetch(url, undefined, { method: "DELETE" });
}

/**
 * Performs a GET call.
 *
 * @param {string} url - A path to be prefixed with '/api' and fetched.
 * @returns {ApiResponse}
 */
export function get(url) {
  return _fetch(url, undefined, { method: "GET" });
}

/**
 * Performs a PATCH call.
 *
 * @param {string} url
 * @param {object} [body]
 * @returns {ApiResponse}
 */
export function patch(url, body) {
  return _fetch(url, body, { method: "PATCH" });
}

/**
 * Performs a POST call.
 *
 * @param {string} url
 * @param {object} [body]
 * @returns {ApiResponse}
 */
export function post(url, body) {
  return _fetch(url, body, { method: "POST" });
}

/**
 * Performs a PUT call.
 *
 * @param {string} url
 * @param {object} [body]
 * @returns {ApiResponse}
 */
export function put(url, body) {
  return _fetch(url, body, { method: "PUT" });
}

/**
 * Calls fetch on a URL that is prefixed with "/api", and returns the result
 * as JSON or catches an error and returns that.
 *
 * @private
 */
async function _fetch(url, body, fetchOverrides = {}) {
  const fetchOptions = merge(
    {
      method: "POST",
      body: JSON.stringify(body),
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json",
      },
    },
    fetchOverrides
  );

  try {
    return { data: await fetcher("/api" + url, fetchOptions) };
  } catch (error) {
    return { error };
  }
}
