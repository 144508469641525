import { Box, CircularProgress } from "@material-ui/core";

export default function FullScreenLoader() {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      minHeight="100vh"
      color="rgba(120, 120, 120, .5)"
    >
      <CircularProgress size={36} color="inherit" />
    </Box>
  );
}
