import logger from "@cosy/lib/logger";
import APIError from "@cosy/utils/api_error";
import apiHostPrefix from "@cosy/utils/api_host_prefix";

/**
 * Fetcher function for `utils/api` and SWR.
 *
 * @param {...(string|object)} args - First argument is always a URL string,
 * second is optional options object.
 * @returns {Promise<?object>}
 */
export default async function fetcher(...args) {
  args[0] = apiHostPrefix + args[0];
  const res = await fetch(...args);

  const [url, options] = args;
  const method = options?.method ?? "GET";

  logger.debug("Fetch %s %s: %o", method, url, options);

  if (!res.ok) {
    logger.error("Fetch error: %s %o", url, options);
    throw new APIError(res);
  }

  const data = res.status === 204 ? undefined : await res.json();
  logger.info("Response %s %s: %o", method, url, data);

  return data;
}
