import secondary from "@material-ui/core/colors/grey";
import { createTheme, responsiveFontSizes } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { ThemeProvider } from "@material-ui/styles";
import merge from "lodash/merge";
import { rgba } from "polished";
import { createContext, useContext, useMemo, useState } from "react";

import { linear, easeInCubic, easeOutCubic } from "@cosy/utils/easing";

const CustomThemeContext = createContext({});

export const FONT_FAMILY = [
  "Walsheim",
  "system-display",
  "system-ui",
  "-apple-system",
  "BlinkMacSystemFont",
  '"Segoe UI"',
  "Roboto",
  '"Helvetica Neue"',
  "Arial",
  "sans-serif",
  '"Apple Color Emoji"',
  '"Segoe UI Emoji"',
  '"Segoe UI Symbol"',
].join(",");

/**
 * Smooth drop shadows layer on each other to create a richer effect.
 *
 * @see
 */
const _SHADOW_LAYERS = 3;
const _SHADOW_MAX_ALPHA = 0.3;
const _SHADOW_MIN_DISTANCE = 2;
const _SHADOW_MAX_DISTANCE = 24;
const _SHADOW_MIN_BLUR = 2;
const _SHADOW_MAX_BLUR = 24;

/**
 * @private
 */
function _getEasedValue(min, max, easeValue) {
  return easeValue * (max - min) + min;
}

/**
 * Creates a layered `box-shadow` at a specified elevation.
 *
 * @private
 * @returns {string}
 */
function _smoothShadow(elevation) {
  // Value from 0 to 1
  const relativeElevation = (1 / 24) * (elevation + 1);

  const distance = _getEasedValue(
    _SHADOW_MIN_DISTANCE,
    _SHADOW_MAX_DISTANCE,
    linear(relativeElevation)
  );
  const blur = _getEasedValue(
    _SHADOW_MIN_BLUR,
    _SHADOW_MAX_BLUR,
    linear(relativeElevation)
  );
  const alpha = _SHADOW_MAX_ALPHA / _SHADOW_LAYERS;

  // Return all shadows in one rule, along with an inset highlight
  return (
    [...Array(_SHADOW_LAYERS)]
      .map((_, index) => {
        const axis = (1 / _SHADOW_LAYERS) * (_SHADOW_LAYERS - index);
        const finalDistance = easeInCubic(axis) * distance;
        const finalBlur = easeInCubic(axis) * blur;
        const finalAlpha = easeOutCubic(axis) * alpha;
        return `0 ${finalDistance}px ${finalBlur}px 0 rgba(0,0,0,${finalAlpha})`;
      })
      .join(", ") +
    `, inset 0 0 0 1px rgba(255,255,255,${
      (_SHADOW_MAX_ALPHA * relativeElevation) / 2
    })`
  );
}

// We generate 24 different elevations
const shadows = [...Array(24)].map((_, i) => _smoothShadow(i));

/**
 * Base theme.
 */
const THEME_BASE = {
  palette: {
    primary: {
      main: "#ff6462",
    },
    secondary: {
      main: secondary[600],
    },
  },
  typography: {
    useNextVariants: true,
    fontFamily: FONT_FAMILY,
    fontSize: 15,
    overline: {
      fontSize: 15,
      textTransform: "none",
    },
    button: {
      fontFamily: FONT_FAMILY,
      textTransform: "none",
      fontWeight: 500,
    },
    h1: {
      fontSize: "2.3rem",
      letterSpacing: "-.02em",
      fontWeight: 500,
    },
    h2: {
      fontSize: 22,
      fontWeight: 500,
    },
    h3: {
      fontSize: 20,
      fontWeight: 500,
    },
    h4: {
      fontSize: 18,
      fontWeight: 500,
    },
    h6: {
      fontSize: 16,
      fontWeight: 400,
    },
  },
  shadows: ["none", ...shadows],
  shape: {
    borderRadius: 6,
  },
  props: {
    MuiTextField: {
      variant: "outlined",
      fullWidth: true,
      size: "small",
    },
  },
};

/**
 * Provider for the custom theme. Exposes a function `setCustomTheme`, which
 * allows the user to pass an object which gets deep merged into the default
 * theme, then generated with Material UI.
 *
 * @example
 * import { setCustomTheme } from useCustomTheme();
 * // Update all Buttons and states, Progress elements, etc. which use
 * // color="primary" to use red.
 * setCustomTheme( { palette: { primary: { main: '#ff0000' } } });
 * @param {object} props
 * @param {*} props.children
 * @param {object} props.initialValue - Initial custom theme.
 * @returns {*}
 */
export function CustomThemeProvider({ children, initialValue = {} }) {
  const isDark = useMediaQuery("(prefers-color-scheme: dark)", {});
  const [customTheme, setCustomTheme] = useState(initialValue);

  const theme = useMemo(() => {
    const newTheme = createTheme(
      merge(
        {},
        THEME_BASE,
        process.browser && isDark ? { palette: { type: "dark" } } : {},
        customTheme
      )
    );

    newTheme.overrides = merge(_getOverrides(newTheme), newTheme.overrides);

    return responsiveFontSizes(newTheme);
  }, [customTheme, isDark]);

  return (
    <CustomThemeContext.Provider value={{ setCustomTheme, theme }}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </CustomThemeContext.Provider>
  );
}
/**
 * All component overrides are created in a function, so can reference the
 * pre-created theme itself (for color palette, spacing functions, etc.).
 *
 * @private
 * @param theme
 */
function _getOverrides(theme) {
  return Object.assign({}, theme.overrides, {
    MuiBackdrop: {
      root: {
        backgroundColor: rgba(theme.palette.background.default, 0.85),
        backdropFilter: "saturate(180%) blur(8px)",
      },
    },
    MuiButton: {
      root: {
        lineHeight: 1.6,
      },
      text: {
        paddingRight: theme.spacing(1.5),
        paddingLeft: theme.spacing(1.5),
      },
      contained: {
        "&:active": {},
      },
      containedPrimary: {
        boxShadow:
          shadows[2] + ", 0 4px 8px " + rgba(theme.palette.primary.main, 0.2),
        "&:active": {
          boxShadow:
            shadows[1] + ", 0 2px 4px " + rgba(theme.palette.primary.main, 0.2),
        },
      },
    },
    MuiButtonBase: {
      root: {
        "&:active": {
          transform: "scale(.97)",
        },
      },
    },
    MuiCard: {
      root: {
        borderRadius: 8,
      },
    },
    MuiDialog: {
      paper: {
        borderRadius: 10,
      },
    },
    MuiDialogActions: {
      root: {
        padding: theme.spacing(2, 3),
      },
    },
    MuiDialogTitle: {
      root: {
        lineHeight: 2,
        fontWeight: 500,
      },
    },
    MuiDivider: {
      root: {
        margin: "8px 0",
      },
    },
    MuiGridListTile: {
      tile: {
        overflow: "visible",
      },
    },
    MuiList: {
      padding: {
        paddingRight: 8,
        paddingLeft: 8,
      },
    },
    MuiListItem: {
      button: {
        "&:active": {
          transform: "scale(1)",
        },
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: 32,
        color: "currentColor",
      },
    },
    MuiListItemText: {
      primary: {
        fontSize: theme.typography.body2.fontSize,
      },
    },
    MuiListSubheader: {
      gutters: {
        lineHeight: 1,
        // We can't use CSS shorthand here, as we want to explicitly overwrite
        // left and right from default styles
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(1),
      },
    },
    MuiMenu: {
      paper: {
        minWidth: 200,
        background: rgba(theme.palette.background.paper, 0.8),
        backdropFilter: "saturate(180%) blur(20px)",
      },
    },
    MuiMenuItem: {
      gutters: {
        padding: theme.spacing(0.5, 1),
      },
      root: {
        borderRadius: 6,
      },
    },
    MuiSkeleton: {
      wave: {
        WebkitMaskImage: "-webkit-radial-gradient(white, black)",
      },
    },
    MuiTabs: {
      root: {
        position: "relative",

        "&:after": {
          content: '""',
          display: "block",
          position: "absolute",
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: 0,
          borderBottom: `2px solid ${theme.palette.divider}`,
        },
      },
      scroller: {
        zIndex: 1,
      },
    },
    MuiTooltip: {
      tooltip: {
        color: theme.palette.text.primary,
        backgroundColor: rgba(theme.palette.background.paper, 0.8),
        backdropFilter: "saturate(180%) blur(5px)",
        boxShadow: theme.shadows[2],
        padding: "8px 16px",
      },
      tooltipPlacementBottom: {
        margin: "0 0",
      },
      popper: {
        // This fixes an issue with using background blur.
        // Might have perf implications.
        willChange: "auto !important",
      },
    },
    MuiTouchRipple: {
      rippleVisible: {
        opacity: 0.02,
      },
    },
    MuiCssBaseline: {
      "@global": {
        "*": {
          "box-sizing": "border-box",
          "caret-color": theme.palette.primary.main,
        },
        strong: {
          fontWeight: 500,
        },
        html: {
          WebkitFontSmoothing: "antialiased",
          fontKerning: "normal",
          textRendering: "optimizeLegibility",
          WebkitFontVariantLigatures: "common-ligatures",
          fontVariantLigatures: "common-ligatures contextual",
          MozOsxFontSmoothing: "grayscale",
        },
        ".typography-rounded": {
          fontFamily: "ui-rounded, " + FONT_FAMILY,
        },
        h1: theme.typography.h1,
        h2: theme.typography.h2,
        h3: theme.typography.h3,
      },
    },
  });
}

/**
 * React hook for using the custom theme.
 *
 * @returns {object} Object with `theme` and `setCustomTheme`.
 */
export default function useCustomTheme() {
  return useContext(CustomThemeContext);
}
