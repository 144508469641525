import { useRouter } from "next/router";
import { useEffect, useCallback, useState } from "react";

import useMe from "./use_me";

import logger from "@cosy/lib/logger";
import { put } from "@cosy/utils/api";

/**
 * React Hook which automatically checks the current URL for a `token` in the
 * query, and, if present, attempts to log the user in using that token.
 *
 * @returns {object} An object with `isCheckingToken`: A boolean which
 * represents if we're in the process of validating a token.
 */
export default function useCheckLoginToken() {
  const {
    replace,
    query: { token },
  } = useRouter();
  const [isCheckingToken, setIsCheckingToken] = useState(!!token);
  const [hasTokenRedirect, setHasTokenRedirect] = useState(false);
  const { mutate } = useMe();
  const checkToken = useCallback(async () => {
    logger.info("Token-based login attempt");
    const { data, error } = await put("/me/token", { token: token[0] });
    if (data) {
      mutate();
      if (data.redirect) {
        setHasTokenRedirect(true);
        replace(data.redirect);
      }
    } else {
      logger.trace("Could not verify token %s", token[0], error);
    }

    setIsCheckingToken(false);
  }, [token, mutate, replace]);

  useEffect(() => {
    if (token) {
      setIsCheckingToken(true);
      checkToken();
    }
  }, [setIsCheckingToken, token, checkToken]);

  return { isCheckingToken, hasTokenRedirect };
}
