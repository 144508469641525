import logger from "pino";

const level =
  process.env.NODE_ENV === "production"
    ? "info"
    : process.env.LOGGER_LEVEL || "trace";

export default logger({
  formatters: {
    log: _formatStackTraces,
  },
  prettyPrint: {
    ignore: "pid,hostname",
    translateTime: true,
  },
  level,
});

const _IGNORED_LINE_KEYWORDS = [
  "node_modules",
  "internal/process/task_queues",
  "<anonymous>",
];

/**
 * Looks for Errors as top-level log objects, and cleans their stack traces by
 * removing the duplicate message line, lines for built-ins, node_modules, etc.,
 * and abbreviates the Webpack file prefix.
 *
 * @private
 */
function _formatStackTraces(object) {
  if (object.type !== "Error" || !object.stack) {
    return object;
  }
  const { stack } = object;
  const lines = stack.split("\n");

  // Filter and format lines
  object.stack = lines
    .filter((line) => !_IGNORED_LINE_KEYWORDS.some((kw) => line.includes(kw)))
    .map((line) => line.replace("webpack-internal:///", ""))
    .join("\n");

  return object;
}
