import {
  Box,
  Button,
  Divider,
  Grid,
  Link,
  TextField,
  Typography,
} from "@material-ui/core";
import Head from "next/head";
import { useRouter } from "next/router";
import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";

import FullScreenLoader from "@cosy/components/FullScreenLoader";
import useCheckLoginToken from "@cosy/hooks/use_check_login_token";
import useMe from "@cosy/hooks/use_me";
import useWorkspaceSWR from "@cosy/hooks/use_workspace_swr";
import { STATUSES } from "@cosy/lib/constants/memberships";
import logger from "@cosy/lib/logger";
import VALID_EMAIL_RE from "@cosy/lib/validators/email";
import { post } from "@cosy/utils/api";

export default function LoginView() {
  const { me, error } = useMe();
  const { replace, asPath } = useRouter();
  const { data: workspace, isValidating } = useWorkspaceSWR("/public");
  const { isCheckingToken, hasTokenRedirect } = useCheckLoginToken();
  const [isTokenSent, setIsTokenSent] = useState(false);

  const redirectPath = _doesPathRedirect(asPath) ? asPath : null;

  useEffect(() => {
    if (!me || hasTokenRedirect) {
      return;
    }

    const redirect = _getRedirectArgs(me);

    logger.debug("Redirecting from LoginView to %o", redirect);
    replace(...redirect);
  }, [replace, me, hasTokenRedirect]);

  if (isCheckingToken || isValidating || !error) {
    return <FullScreenLoader />;
  }

  return (
    <LayoutWrapper>
      <Head>
        <title>
          {workspace
            ? `Sign in to ${workspace.name} on Cosy`
            : "Sign in to Cosy"}
        </title>
      </Head>
      <Box maxWidth="100%" width={400} m="0 auto">
        {isTokenSent && <CheckYourInboxMessage />}
        {!isTokenSent && (
          <Grid container direction="column" spacing={4}>
            <Grid item>
              <WelcomeMessage workspace={workspace} />
            </Grid>
            <Grid item>
              <GoogleButton redirectPath={redirectPath} />
            </Grid>
            <Grid item>
              <Divider />
            </Grid>
            <Grid item>
              <EmailForm onSend={setIsTokenSent} redirectPath={redirectPath} />
            </Grid>
            <Grid item>
              <Footer />
            </Grid>
          </Grid>
        )}
      </Box>
    </LayoutWrapper>
  );
}

function CheckYourInboxMessage() {
  return (
    <Grid item align="center">
      <Typography variant="h2" align="center" gutterBottom>
        Inbox time!
      </Typography>
      <Typography variant="body1" align="center" paragraph>
        To sign in without a password, use the link in the email we just sent
        you.
      </Typography>
      <Button
        href="https://mail.google.com/mail/u/0/#search/Cosy.app+in%3Aanywhere"
        target="_blank"
        variant="outlined"
        startIcon={
          <img alt="" src="/img/apps/gmail.png" width="24" height="24" />
        }
      >
        Open Gmail
      </Button>
    </Grid>
  );
}

function EmailForm({ onSend, redirectPath }) {
  const { register, handleSubmit, getValues, formState, setError } = useForm();

  const { errors } = formState;

  async function submitUserEmail() {
    const opts = getValues();
    if (redirectPath) {
      opts.redirectPath = redirectPath;
    }

    const result = await post("/me/token", opts);
    if (result.error) {
      setError("email", {
        type: "manual",
        message: "This email is not yet able to create an account.",
      });
      return;
    }
    onSend(true);
  }

  const { ref: emailRef, ...emailProps } = register("email", {
    pattern: {
      value: VALID_EMAIL_RE,
      message: "That doesn’t look like a valid email address!",
    },
  });

  return (
    <form onSubmit={handleSubmit(submitUserEmail)}>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <TextField
            label="Email"
            autoComplete="off"
            type="email"
            placeholder="Enter your email…"
            helperText={errors.email ? errors.email.message : ""}
            error={!!errors.email}
            inputRef={emailRef}
            {...emailProps}
          />
        </Grid>
        <Grid item>
          <Button
            fullWidth
            size="large"
            color="primary"
            variant="contained"
            disabled={
              !!errors.email || !formState.isDirty || formState.isSubmitting
            }
            type="submit"
            onSubmit={handleSubmit(submitUserEmail)}
          >
            Continue with email
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}

function LayoutWrapper({ children }) {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      minHeight="100vh"
    >
      {children}
    </Box>
  );
}

function WelcomeMessage({ workspace }) {
  return (
    <Typography variant="h1" align="center" gutterBottom>
      {workspace?.name ? (
        <span>
          Sign in to <strong>{workspace.name}</strong> on Cosy
        </span>
      ) : (
        "Sign in to Cosy"
      )}
    </Typography>
  );
}

function GoogleButton({ redirectPath }) {
  return (
    <Button
      href={`${process.env.NEXT_PUBLIC_HOST}/api/oauth/google/redirect?${
        redirectPath ? `redirectPath=${redirectPath}` : ""
      }`}
      size="large"
      variant="outlined"
      fullWidth
    >
      <img
        src="/img/result-icons/websearches/google.svg"
        alt=""
        width="24"
        style={{ marginRight: 8 }}
      />
      Continue with Google
    </Button>
  );
}

function Footer() {
  return (
    <Typography variant="body2" color="textSecondary" paragraph align="center">
      <Link href={`${process.env.NEXT_PUBLIC_HOST}/`}>What’s Cosy?</Link>
    </Typography>
  );
}

function _getRedirectArgs(me) {
  const membership = me.memberships?.find((m) =>
    [STATUSES.ACTIVE.id].includes(m.status)
  );

  if (membership?.workspace?.slug) {
    return ["/[slug]", "/" + membership.workspace.slug];
  } else {
    return ["/onboarding"];
  }
}

function _doesPathRedirect(asPath) {
  return asPath !== "/chrome-new-tab.html" && !asPath?.includes("/login");
}
