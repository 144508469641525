/**
 * Function for SWR error retry rules.
 *
 * @param {object} error
 * @param {string} key
 * @param {object} config
 * @param {Function} revalidate
 * @param {object} revalidateOps
 * @param {number} revalidateOps.retryCount
 */
export default async function onErrorRetry(
  error,
  key,
  config,
  revalidate,
  { retryCount } = {}
) {
  // Retry only on error status 500 or bigger.
  if (error.status < 500) {
    return;
  }

  // Only retry up to 10 times.
  if (retryCount >= 10) {
    return;
  }

  // exponential backoff
  const count = Math.min(retryCount, 8);
  const timeout =
    ~~((Math.random() + 0.5) * (1 << count)) * config.errorRetryInterval;
  setTimeout(() => revalidate({ retryCount }), timeout);
}
