import CssBaseline from "@material-ui/core/CssBaseline";
import App from "next/app";
import { IntercomProvider } from "react-use-intercom";
import { SWRConfig } from "swr";

import AuthContainer from "../components/AuthContainer";
import IndexingSnackbar from "../components/IndexingSnackbar";
import OfflineWarning from "../components/OfflineWarning";
import { IndexJobsProvider } from "../contexts/IndexJobsContext";
import { CustomThemeProvider } from "../hooks/use_custom_theme";
import { MeProvider } from "../hooks/use_me";
import fetcher from "../utils/fetcher";
import onErrorRetry from "../utils/on_error_retry";

/**
 * Includes a bunch of funkiness for server-rendered material-ui styles.
 */
class Cosy extends App {
  componentDidMount() {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector("#jss-server-side");

    jssStyles?.parentNode.removeChild(jssStyles);

    // Install the Service Worker
    // if ("serviceWorker" in navigator) {
    //   console.log("Registering service worker");
    //   navigator.serviceWorker
    //     .register("/_next/service-worker.js", { scope: "/" })
    //     .catch((err) =>
    //       console.error("Service worker registration failed", err)
    //     );
    // } else {
    //   console.log("😢 Service worker not supported");
    // }

    // Force-cache the index page
    // window.addEventListener("load", async () => {
    //   try {
    //     const myCache = await window.caches.open("offline-page");
    //     await myCache.addAll(["/?offline"]);
    //   } catch (e) {
    //     console.error(e);
    //   }
    // });
  }

  render() {
    const { Component, pageProps } = this.props;
    const getLayout = Component.getLayout || ((page) => page);

    return (
      <SWRConfig value={{ fetcher, onErrorRetry }}>
        <CustomThemeProvider>
          <CssBaseline />
          <IntercomProvider appId={process.env.NEXT_PUBLIC_INTERCOM_APP_ID}>
            <MeProvider>
              <AuthContainer authRequired={Component.authRequired}>
                <OfflineWarning>
                  <IndexJobsProvider>
                    <IndexingSnackbar />
                    {getLayout(<Component {...pageProps}></Component>)}
                  </IndexJobsProvider>
                </OfflineWarning>
              </AuthContainer>
            </MeProvider>
          </IntercomProvider>
        </CustomThemeProvider>
        <style global jsx>{`
          @font-face {
            font-family: Walsheim;
            font-display: fallback;
            font-weight: 400;
            src: url("/fonts/GT-Walsheim-Regular.woff2") format("woff2");
          }
          @font-face {
            font-family: Walsheim;
            font-display: fallback;
            font-weight: 500;
            src: url("/fonts/GT-Walsheim-Medium.woff2") format("woff2");
          }

          @font-face {
            font-family: Walsheim;
            font-display: fallback;
            font-weight: 600;
            src: url("/fonts/GT-Walsheim-Bold.woff2") format("woff2");
          }
        `}</style>
      </SWRConfig>
    );
  }
}

export default Cosy;
