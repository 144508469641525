import { useCallback, useEffect, useState, useMemo } from "react";

import logger from "@cosy/lib/logger";

/**
 * React hook for determining if the browser is (or goes) offline.
 *
 * @returns {boolean} Whether or not the browser is offline.
 */
export default function useOffline() {
  // We mark ourselves as online for server rendering
  const nav = useMemo(
    () => (process.browser ? window.navigator : { onLine: true }),
    []
  );
  const [offline, setOffline] = useState(!nav.onLine);

  const updateOnlineStatus = useCallback(() => {
    logger.info("Updating status", nav.onLine);
    setOffline(!nav.onLine);
  }, [nav]);

  useEffect(() => {
    logger.debug("Adding online/offline listeners");
    window.addEventListener("online", updateOnlineStatus);
    window.addEventListener("offline", updateOnlineStatus);

    return () => {
      logger.debug("Removing online/offline listeners");
      window.removeEventListener("online", updateOnlineStatus);
      window.removeEventListener("offline", updateOnlineStatus);
    };
  }, [updateOnlineStatus]);

  return offline;
}
