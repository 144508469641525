import { useRouter } from "next/router";
import { useState, useEffect } from "react";

import useMe from "./use_me";

import useLastVisitedWorkspace from "@cosy/hooks/use_last_visited_workspace";
import { STATUSES } from "@cosy/lib/constants/memberships";
/**
 * React hook which returns a stateful `slug` string, if it’s found in the
 * URL. If not, will return from the user’s first membership it can find.
 *
 * @returns {(string|null)}
 */
export default function useSlug() {
  const {
    query: { slug: querySlug },
  } = useRouter();
  const { me } = useMe();
  const [lastVisitedWorkspace] = useLastVisitedWorkspace();
  const [slug, setSlug] = useState(
    _getSlug(querySlug, lastVisitedWorkspace, me)
  );

  useEffect(() => {
    setSlug(_getSlug(querySlug, lastVisitedWorkspace, me));
  }, [querySlug, lastVisitedWorkspace, me]);

  return slug;
}

/**
 * The Chrome extension presents a special case where query.slug is returned as
 * "chrome-new-tab.html". In this case, we want to fall back to the first active
 * membership.
 *
 * @private
 */
function _getSlug(querySlug, lastVisitedWorkspace, user) {
  if (querySlug && querySlug !== "chrome-new-tab.html") {
    return querySlug;
  } else if (lastVisitedWorkspace) {
    return lastVisitedWorkspace;
  }

  return _getFirstActiveMembershipSlug(user);
}

/**
 * @private
 */
function _getFirstActiveMembershipSlug(user) {
  return user?.memberships.find((m) => m.status === STATUSES.ACTIVE.id)
    ?.workspace.slug;
}
