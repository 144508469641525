import Snackbar from "@material-ui/core/Snackbar";
import WarningIcon from "@material-ui/icons/Warning";
import { useEffect, useState } from "react";

import useOffline from "../hooks/use_offline";

export default function OfflineWarning({ children }) {
  const isOffline = useOffline();

  const [snackbarMessage, setSnackbarMessage] = useState(false);

  useEffect(() => {
    if (isOffline) {
      setSnackbarMessage("Your connection appears to be offline.");
    } else {
      setSnackbarMessage(false);
    }
  }, [isOffline]);

  function closeSnackbar(event, reason) {
    if (reason !== "clickaway") {
      setSnackbarMessage(false);
    }
  }

  return (
    <div>
      <div className={isOffline ? "app-offline" : ""}>{children}</div>
      <Snackbar
        open={!!snackbarMessage}
        onClose={closeSnackbar}
        ContentProps={{
          "aria-describedby": "message-id",
        }}
        message={
          <span className="offline-message">
            <WarningIcon />
            <span id="message-id">{snackbarMessage}</span>
          </span>
        }
      />
      <style jsx>{`
        .app-offline {
          opacity: 0.5;
          pointer-events: none;
        }
        .offline-message {
          display: flex;
          align-items: center;
        }
      `}</style>
    </div>
  );
}
