import CircularProgress from "@material-ui/core/CircularProgress";
import Snackbar from "@material-ui/core/Snackbar";

import useIndexing from "../hooks/use_indexing";

export default function IndexingSnackbar() {
  const { indexJobs } = useIndexing();

  if (!indexJobs.length) {
    return null;
  }

  return (
    <Snackbar
      open
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      ContentProps={{
        "aria-describedby": "message-id",
      }}
      message={
        <span id="message-id" style={{ display: "flex", alignItems: "center" }}>
          <CircularProgress size={24} style={{ marginRight: 8 }} /> Indexing…
        </span>
      }
    />
  );
}
