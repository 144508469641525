import * as Sentry from "@sentry/browser";
import { useEffect, createContext, useContext } from "react";
import useSWR from "swr";

const MeContext = createContext();

/**
 * Hook for components to get current user data from parent Provider.
 *
 * @returns {object} - Values of the MeContext Provider.
 */
export default function useMe() {
  return useContext(MeContext);
}

/**
 * Provider component that makes current user data available
 * to any children that calls useMe().
 *
 * @param {object} props
 * @param {object} [props.value] - Initial user data to set before fetching.
 * @param {object} props.children
 * @returns {*}
 */
export function MeProvider({ value, children }) {
  const options = {
    // We want to avoid re-ordering/changing any search results currently
    // visible. This is possible when calling /api/me, as the Algolia search
    // API key is always re-generated.
    revalidateOnFocus: false,
  };
  if (value) {
    options.fallbackData = value;
    options.revalidateOnMount = false;
  }
  const { data: me, error, mutate } = useSWR("/api/me", options);

  useEffect(() => {
    if (me) {
      Sentry.setUser({ email: me.email, username: me.name });
    }
  }, [me]);

  return (
    <MeContext.Provider value={{ me, mutate, error }}>
      {children}
    </MeContext.Provider>
  );
}
