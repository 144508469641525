import { useContext } from "react";

import { IndexJobsContext } from "../contexts/IndexJobsContext";

/**
 * React Hook for setting and reading the current value of isIndexing, which
 * corresponds with a Snackbar component shown in the UI.
 *
 * @returns {IndexJobsContext} An object with isIndexing and setIsIndexing.
 */
export default function useIndexing() {
  return useContext(IndexJobsContext);
}
